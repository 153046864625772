s<template>
  <v-row class="flex-wrap justify-center fixed">
    <v-col cols="6">
      <v-row class="mb-10">
        <v-col cols="8" class="cLogo">
          <v-img width="80" src="../../assets/logoMis.png" />
        </v-col>
      </v-row>

      <!-- back button -->
      <v-row class="mt-10" align="center" justify="center">
        <v-col cols="12" xl="6" md="10" xs="12" sm="12">
          
            <v-btn
              icon
              elevation="0"
              color="gray"
              @click="voltar()"
            >
              <v-icon>mdi-arrow-left</v-icon> 
            </v-btn>
         
        </v-col>
      </v-row>

      <!-- label -->
      <v-row class="mt-10" align="center" justify="center">
        <v-col cols="12" xl="6" md="10" xs="12" sm="12">
          <div class="text-h5 font-weight-regular">Esqueci minha senha</div>
          <div class="subtitle font-weight subt">Recuperação de senha</div>
        </v-col>
      </v-row>

      <!-- input -->
      <div v-if="confirmation == false">
        <v-row class="mt-10" align="center" justify="center">
          <v-col cols="12" xl="6" md="10" xs="12" sm="12">
            <v-form @submit.prevent="submit">
              <v-text-field
                v-model="email"
                placeholder="Insira seu Email !"
                type="email"
                label="Email"
                :error="this.error"
                class="input"
                outlined
                clearable
              ></v-text-field>

              <v-btn
                class="white--text pa-6"
                height="60"
                width="100%"
                color="#1c3b7b"
                type="submit"
                onClick="submit"
              >
                Enviar Email de recuperação
              </v-btn>
            </v-form>
            <div class="mt-3"></div>
            <div class="text-center"></div>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row class="mt-10" align="center" justify="center">
          <v-col cols="12" xl="6" md="10" xs="12" sm="12">
            <p>
              Um solicitação de redefinição de senha foi
              <span class="span">enviada para o seu e-mail</span>. Por favor
              aguarde pelo menos 15 minutos e confirma o recebimento.<br />
              <span class="span">Caso não tenha recebido </span>o e-mail,
              solicite novamente no botão abaixo.
            </p>

          </v-col> 
          <v-col cols="12" align="center">
            <v-btn
              class="input white--text mt-6"
              height="60"
              color="#1c3b7b"
              @click="submit()"
            >
              Reenviar e-mail
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="6" class="pa-0" v-if="!$vuetify.breakpoint.mobile">
      <v-img
        class="wallpaper"
        :lazy-src="require('../../assets/login.png')"
        :src="require('../../assets/login.png')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PasswordForm",
  data() {
    return {
      email: "",
      password: "",
      error: false,
      confirmation: false,
    };
  },
  methods: {
     voltar() {
      // atualiza a chave da rota para forçar a recriação do componente
      this.routeKey++;
      this.$router.go(-1); // redireciona para a rota anterior
    },
    submit() {  
      this.$axios
        .post("reset_password/", { email: this.email })
        .then(() => this.submitSuccessful())
        .catch((error) => this.submitFailed(error));
    },  
    submitSuccessful() {
      this.$swal({
        position: "center",
        showConfirmButton: true,
        icon: "success",
        title: "MIS",
        text: "Email de redefinição de senha enviado com sucesso!",
      });
      this.error = false;
      this.confirmation = true;
    },
    submitFailed(error) {
      console.log("deu não");
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        "";
      const msg = this.error;

      this.$swal({
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "MIS",
        text: "Ocorreu um erro ao enviar seu email de redefinição de senha. Verifique seu email e tente novamente daqui alguns minutos!",
        msg,
      });
      },
      backToLogin() {
      this.$router.push({
        path: "/",
      });
    },
    resend() {
      this.confirmation = true
    },
  },
};
</script>
<style>
.subt {
  color: #1c3b7b;
}
.btn {
  margin-left: 6vh;
}
.span {
  color: #1c3b7b;
  font-weight: bold;
}
.logo {
  width: 304px;
  height: 75px;
  padding: 95px;
}
.wallpaper {
  background-image: url("../../assets/login.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  flex: 1;
}
</style>
